import type { ApplicationConfig } from '@angular/core';
import { provideExperimentalZonelessChangeDetection } from '@angular/core';
import {
    AppFeature,
    BatteryCertificateFeature,
    DealerFeature,
    FootnotesFeature,
    HyundaiPromiseFeature,
    LoginFeature,
    Platform,
    provideAutocoreApiConfig,
    provideCore,
    provideDealcoreApiConfig,
    providePlatformToken,
    provideShowroomConfig,
    provideTrackingEnv,
    provideVehicleMarketUrl,
    TrackingFeature,
    UiFeature,
} from '@hyundai/ng-common-lib';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { environment } from '../environments/environment';
import { createRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideCore({
            routes: createRoutes(),
            cloudinaryBaseUrl: environment.cloudinaryBaseUrl,
            showroomScriptUrl: environment.showroomScriptUrl,
            e2e: environment.e2e,
        }),
        provideDealcoreApiConfig({ baseUrl: environment.dealcoreBaseUrl }),
        provideAutocoreApiConfig({ baseUrl: environment.autocoreBaseUrl }),
        provideShowroomConfig({ scriptUrl: environment.showroomScriptUrl }),
        provideVehicleMarketUrl(environment.vehicleMarketBaseUrl),
        provideState(AppFeature.state),
        provideState(LoginFeature.state),
        provideState(DealerFeature.state),
        provideState(UiFeature.state),
        provideState(FootnotesFeature.state),
        provideState(TrackingFeature.state),
        provideState(HyundaiPromiseFeature.state),
        provideState(BatteryCertificateFeature.state),
        provideEffects([
            AppFeature.effects,
            LoginFeature.effects,
            DealerFeature.effects,
            UiFeature.effects,
            FootnotesFeature.effects,
            TrackingFeature.effects,
            HyundaiPromiseFeature.effects,
            BatteryCertificateFeature.effects,
        ]),
        providePlatformToken(Platform.HWS),
        provideTrackingEnv(environment.trackingTerm),
    ],
};
